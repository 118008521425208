import {Inject, Injectable, InjectionToken} from "@angular/core";
import {HttpClient, HttpParams} from "@angular/common/http";
import {Observable} from "rxjs";
import {
  AgencyPrebuiltAutomation,
  AutomationBudgetGraphData,
  AutomationBudgetStatusResponse,
  AutomationComponent,
  AutomationComponentSearchParams,
  AutomationComponentSettings,
  AutomationComponentType,
  AutomationCreditUsageEstimateParams,
  AutomationCustomCriteriaInfo,
  AutomationDefinition,
  AutomationDefinitionCountResponse,
  AutomationDefinitionDetails,
  AutomationDefinitionSearchParams,
  AutomationEstimationResponse,
  AutomationGroupUpdateRequest,
  AutomationPendingContactSearchParams,
  AutomationPendingContactWrapper,
  AutomationReEnrollmentInfo,
  AutomationRunningInstancesResponse,
  AutomationTemplate,
  AutomationTurnOffConfiguration,
  AutomationTurnOnConfiguration,
  AutomationUnenrollmentInfo,
  AutomationUnEnrollmentRequest,
  CreditUsageEstimateResponse,
  FQLFieldExpr,
  FQLOrExpr,
  PagedResult,
  PreBuiltAutomationTemplate
} from "fello-model";

export const WMS_API_BASE_URL = new InjectionToken<string>("WMS_API_BASE_URL");

@Injectable({
  providedIn: "root"
})
export class WMSApiService {
  baseUrl: string;

  constructor(private http: HttpClient, @Inject(WMS_API_BASE_URL) private wmsBaseUrl: string) {
    this.baseUrl = `${this.wmsBaseUrl}/wms`;
  }

  getAutomationTemplates(): Observable<{automationTemplates: AutomationTemplate[]}> {
    return this.http.get<{automationTemplates: AutomationTemplate[]}>(`${this.baseUrl}/automation/template`);
  }

  getAllPrebuiltAutomations(): Observable<AgencyPrebuiltAutomation[]> {
    return this.http.get<AgencyPrebuiltAutomation[]>(`${this.baseUrl}/automation/pre-built-automation`);
  }

  joinWaitList(preBuiltAutomationTemplateId: string): Observable<void> {
    return this.http.post<void>(`${this.baseUrl}/automation/pre-built-automation/${preBuiltAutomationTemplateId}/join-wait-list`, {});
  }

  createAutomationFromTemplate(templateId: string, title: string): Observable<{automationDefinitionId: string}> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/automation/template/${templateId}/create-automation`, {
      title
    });
  }

  //ToDo: replace with search api
  getAutomationDefinitions(): Observable<{
    automationDefinitions: AutomationDefinition[];
  }> {
    return this.http.get<{
      automationDefinitions: AutomationDefinition[];
    }>(`${this.baseUrl}/automation/definition`);
  }

  searchAutomationDefinitions(params: AutomationDefinitionSearchParams): Observable<PagedResult<AutomationDefinition>> {
    return this.http.get<PagedResult<AutomationDefinition>>(`${this.baseUrl}/automation/definition/search`, {
      params: params as unknown as HttpParams
    });
  }

  getLinkedAutomationIds(params: AutomationComponentSearchParams): Observable<{
    linkedAutomationIds: string[];
  }> {
    return this.http.post<{
      linkedAutomationIds: string[];
    }>(`${this.baseUrl}/automation/definition/components/linked-automation-ids`, {params});
  }

  getAutomationDefinitionById(definitionId: string): Observable<{
    automationDefinition: AutomationDefinition | null;
  }> {
    return this.http.get<{
      automationDefinition: AutomationDefinition | null;
    }>(`${this.baseUrl}/automation/definition/${definitionId}`);
  }

  getAutomationDefinitionDetails(definitionId: string): Observable<{
    automationDefinition: AutomationDefinitionDetails;
  }> {
    return this.http.get<{
      automationDefinition: AutomationDefinitionDetails;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/details`);
  }

  turnOnAutomation(
    definitionId: string,
    config: AutomationTurnOnConfiguration
  ): Observable<{
    automationDefinitionId: string;
  }> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/toggle/active`, {config});
  }

  turnOffAutomation(
    definitionId: string,
    config: AutomationTurnOffConfiguration
  ): Observable<{
    automationDefinitionId: string;
  }> {
    return this.http.post<{
      automationDefinitionId: string;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/toggle/inactive`, {config});
  }

  archiveAutomation(definitionId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/automation/definition/${definitionId}/archive`, {});
  }

  unarchiveAutomation(definitionId: string): Observable<unknown> {
    return this.http.post(`${this.baseUrl}/automation/definition/${definitionId}/unarchive`, {});
  }

  validateAutomationTitle(title: string): Observable<{valid: boolean}> {
    return this.http.post<{valid: boolean}>(`${this.baseUrl}/automation/definition/validate/title`, {title});
  }

  updateAutomationTitle(definitionId: string, title: string): Observable<unknown> {
    return this.http.patch(`${this.baseUrl}/automation/definition/${definitionId}/title`, {title});
  }

  updateAutomationEnrollmentCriteria(
    definitionId: string,
    userCriteria: FQLOrExpr | undefined
  ): Observable<{
    automationDefinition: AutomationDefinition;
  }> {
    return this.http.patch<{
      automationDefinition: AutomationDefinition;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/enrollment/user-criteria`, {userCriteria});
  }

  cloneAutomation(definitionId: string, title: string): Observable<AutomationDefinition> {
    return this.http.post<AutomationDefinition>(`${this.baseUrl}/automation/definition/${definitionId}/clone`, {
      title
    });
  }

  getAutomationReEnrollmentAvailableExpressions(definitionId: string): Observable<{
    availableExpressions: FQLFieldExpr[];
  }> {
    return this.http.get<{
      availableExpressions: FQLFieldExpr[];
    }>(`${this.baseUrl}/automation/definition/${definitionId}/re-enrollment/expressions/available`);
  }

  updateAutomationReEnrollmentInfo(
    definitionId: string,
    reEnrollmentInfo: AutomationReEnrollmentInfo
  ): Observable<{
    automationDefinition: AutomationDefinition;
  }> {
    return this.http.put<{
      automationDefinition: AutomationDefinition;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/re-enrollment/criteria`, {reEnrollmentInfo});
  }

  updateAutomationUnenrollmentInfo(
    definitionId: string,
    unenrollmentInfo: AutomationUnenrollmentInfo
  ): Observable<{
    automationDefinition: AutomationDefinition;
  }> {
    return this.http.put<{
      automationDefinition: AutomationDefinition;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/unenrollment/criteria`, {unenrollmentInfo});
  }

  groupUpdateAutomationCriterias(
    definitionId: string,
    payload: AutomationGroupUpdateRequest
  ): Observable<{automationDefinition: AutomationDefinition}> {
    return this.http.put<{
      automationDefinition: AutomationDefinition;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/criteria/group-update`, payload);
  }

  getPreTurnOnMeta(definitionId: string): Observable<{meta: AutomationDefinitionCountResponse}> {
    return this.http.get<{
      meta: AutomationDefinitionCountResponse;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/meta/turn-on/pre`);
  }

  getEntityCountForCriteria(
    definitionId: string,
    criteriaInfo: AutomationCustomCriteriaInfo
  ): Observable<{meta: AutomationDefinitionCountResponse}> {
    return this.http.post<{meta: AutomationDefinitionCountResponse}>(
      `${this.baseUrl}/automation/definition/${definitionId}/meta/entities/criteria-match`,
      {automationCriteria: criteriaInfo}
    );
  }

  getActiveEnrolledRecords(definitionId: string): Observable<{meta: AutomationRunningInstancesResponse}> {
    return this.http.get<{
      meta: AutomationRunningInstancesResponse;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/enrollment/records/active`);
  }

  getAutomationComponentRecords(
    definitionId: string,
    componentId: string
  ): Observable<{
    userCount: number;
  }> {
    return this.http.get<{
      userCount: number;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/user-count`);
  }

  getContactsInComponent(
    definitionId: string,
    componentId: string,
    params: AutomationPendingContactSearchParams
  ): Observable<PagedResult<AutomationPendingContactWrapper>> {
    return this.http.post<PagedResult<AutomationPendingContactWrapper>>(
      `${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/contacts/list`,
      params
    );
  }

  updateAutomationComponentSettings(
    definitionId: string,
    componentId: string,
    settings: AutomationComponentSettings<AutomationComponentType>
  ): Observable<{automationComponent: AutomationComponent<AutomationComponentType>}> {
    return this.http.patch<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/settings`, {
      settings
    });
  }

  turnComponentOn(
    definitionId: string,
    componentId: string
  ): Observable<{
    automationComponent: AutomationComponent<AutomationComponentType>;
  }> {
    return this.http.post<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/turn-on`, {});
  }

  turnComponentOff(
    definitionId: string,
    componentId: string
  ): Observable<{
    automationComponent: AutomationComponent<AutomationComponentType>;
  }> {
    return this.http.post<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/turn-off`, {});
  }

  unEnrollComponentContacts(
    definitionId: string,
    componentId: string,
    request: AutomationUnEnrollmentRequest
  ): Observable<{
    automationComponent: AutomationComponent<AutomationComponentType>;
  }> {
    return this.http.post<{
      automationComponent: AutomationComponent<AutomationComponentType>;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/un-enroll`, {request});
  }

  getBudgetDetails(definitionId: string): Observable<AutomationBudgetStatusResponse> {
    return this.http.get<AutomationBudgetStatusResponse>(`${this.baseUrl}/automation/definition/${definitionId}/budget`);
  }

  getSmartSendConfig(
    definitionId: string,
    componentId: string
  ): Observable<{
    canBeDisabled: boolean;
  }> {
    return this.http.get<{
      canBeDisabled: boolean;
    }>(`${this.baseUrl}/automation/definition/${definitionId}/component/${componentId}/smart-send-config`);
  }

  getBudgetDetailsForAutomations(definitionIds: string[]): Observable<AutomationBudgetStatusResponse[]> {
    return this.http.post<AutomationBudgetStatusResponse[]>(`${this.baseUrl}/automation/definition/budget`, {
      params: {
        definitionIds
      }
    });
  }

  getBudgetUsageGraphData(definitionId: string): Observable<AutomationBudgetGraphData> {
    return this.http.get<AutomationBudgetGraphData>(`${this.baseUrl}/automation/definition/${definitionId}/budget/graph`);
  }

  updateBudget(definitionId: string, capacity: number): Observable<AutomationBudgetStatusResponse> {
    return this.http.post<AutomationBudgetStatusResponse>(`${this.baseUrl}/automation/definition/${definitionId}/budget`, {
      capacity
    });
  }

  removeBudget(definitionId: string): Observable<void> {
    return this.http.delete<void>(`${this.baseUrl}/automation/definition/${definitionId}/budget`);
  }

  getJourneyEstimate(definitionId: string): Observable<AutomationEstimationResponse> {
    return this.http.get<AutomationEstimationResponse>(`${this.baseUrl}/automation/definition/${definitionId}/journey/estimate`);
  }

  getJourneyEstimateForAutomations(definitionIds: string[]): Observable<AutomationEstimationResponse[]> {
    return this.http.post<AutomationEstimationResponse[]>(`${this.baseUrl}/automation/definition/journey/estimate`, {
      params: {
        definitionIds
      }
    });
  }

  getCreditEstimate(definitionId: string, params: AutomationCreditUsageEstimateParams): Observable<CreditUsageEstimateResponse> {
    return this.http.post<CreditUsageEstimateResponse>(
      `${this.baseUrl}/automation/definition/${definitionId}/budget/credit-consumption-estimate/v2`,
      params
    );
  }

  getPrebuiltTemplateDetails(definitionId: string): Observable<PreBuiltAutomationTemplate | null> {
    return this.http.get<PreBuiltAutomationTemplate | null>(
      `${this.baseUrl}/automation/definition/${definitionId}/prebuilt/template-details`
    );
  }
}
