import {Component, Inject, OnInit} from "@angular/core";
import {
  AddressComponent,
  AgentContactProperty,
  ContactDetailsOnlyFormConfigSettings,
  HomeValueFromDashboardRequest,
  SaleTimeline
} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {combineLatest, finalize, Observable, switchMap, take, tap} from "rxjs";
import {MAT_DIALOG_DATA, MatDialogRef} from "@angular/material/dialog";
import {Router} from "@angular/router";
import {phoneNumberPattern} from "../../../../fello-ui-utils/constants/phoneNumberPattern";
import {NgxSpinnerService} from "ngx-spinner";
import {DestroyableBase} from "../../../../../lib/mixins/helpers";
import {GoogleStreetViewImageService} from "../../../../../lib/services/google-image/google-street-view-image.service";
import {AgentContactDashboardApiService} from "../../../../../lib/services/contact-dashboard-api/agent-contact-dashboard-api.service";
import {PropertyPublicDashboardApiService} from "../../../../../lib/services/property-public-dashboard-api/property-public-dashboard-api.service";
import {AgentBrandingUtils, ConsumerConsentService, FelloWebsites} from "../../../../../lib";
import {HttpErrorResponse, HttpStatusCode} from "@angular/common/http";

const ERROR_CODE_MESSAGE_MAP: Record<string, string> = {
  ADDRESS_LIMIT_REACHED: "Address limit reached. You can't add any more addresses.",
  ADDRESS_IS_TOO_LONG: "Invalid Address",
  ADDRESS_INVALID: "Invalid Address",
  ADDRESS_DUPLICATE: "Address already exists"
};

enum AddAddressSteps {
  Address_Entry,
  Address_Confirmation,
  Contact_Details
}

export interface AddAddressComponentData {
  propertyId: string;
  emailId: string;
  hvRequestFromDashboard: HomeValueFromDashboardRequest;
  addAddressAction?: (
    propertyId: string,
    rawAddress: string,
    hvRequestFromDashboard: HomeValueFromDashboardRequest
  ) => Observable<AgentContactProperty>;
}

@Component({
  selector: "lib-add-address",
  templateUrl: "./add-address.component.html",
  styleUrls: ["./add-address.component.scss"]
})
export class AddAddressComponent extends DestroyableBase implements OnInit {
  spinnerName = "add-address";
  address = "";
  phoneNumberPattern = phoneNumberPattern;
  addrComp: AddressComponent | null;

  isLoading = false;
  image: string | null;
  SaleTimeline = SaleTimeline;
  currentStep = AddAddressSteps.Address_Entry;
  AddAddressSteps = AddAddressSteps;
  settings$ = this.publicDashboardApiService.getPropertyDashboardSettings(this.propertyId);
  consentText: string;
  felloWebsites = FelloWebsites;

  homeValueFromDashboardRequest: HomeValueFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: "",
    emailId: ""
  };

  settings?: ContactDetailsOnlyFormConfigSettings;

  get propertyId(): string {
    return this.dialogData.propertyId;
  }
  constructor(
    private snackBar: MatSnackBar,
    private googleStreetViewImageService: GoogleStreetViewImageService,
    @Inject(MAT_DIALOG_DATA)
    public dialogData: AddAddressComponentData,
    private dialogRef: MatDialogRef<unknown>,
    private agentContactApiService: AgentContactDashboardApiService,
    private publicDashboardApiService: PropertyPublicDashboardApiService,
    private router: Router,
    public spinnerService: NgxSpinnerService,
    private consentService: ConsumerConsentService
  ) {
    super();
    this.homeValueFromDashboardRequest = this.dialogData.hvRequestFromDashboard;
  }

  ngOnInit() {
    this.isLoading = true;
    combineLatest([
      this.publicDashboardApiService.getHVFormSettings(this.propertyId),
      this.publicDashboardApiService.getPropertyDashboardSettings(this.propertyId)
    ])
      .pipe(
        take(1),
        finalize(() => {
          this.isLoading = false;
        })
      )
      .subscribe(([formConfigSettings, dashboardSettings]) => {
        this.settings = formConfigSettings;
        this.consentText = AgentBrandingUtils.getAgencyConsentText(dashboardSettings);
      });
  }

  validateAddress(): void {
    this.addrComp = null;
    this.isLoading = true;
    this.image = null;
    this.agentContactApiService
      .validateAddressDirect(this.address)
      .pipe(
        take(1),
        tap(a => {
          this.googleStreetViewImageService
            .getImage(a)
            .pipe(
              take(1),
              tap(image => {
                if (image) {
                  this.image = image;
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe(
        addr => {
          this.addrComp = addr;
          this.isLoading = false;
          this.currentStep = AddAddressSteps.Address_Confirmation;
        },
        () => {
          this.snackBar.open("The address you selected is invalid. Please try with correct address", undefined, {
            duration: 2000
          });
          this.isLoading = false;
        }
      );
  }

  addAddress(): void {
    this.spinnerService.show(this.spinnerName);
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }

    this.consentService
      .createConsentTracker(this.consentText)
      .pipe(
        take(1),
        switchMap(({consentTrackerId}) => {
          homeValueRequest.consentTrackerId = consentTrackerId;
          return this.dialogData.addAddressAction
            ? this.dialogData.addAddressAction(this.propertyId, this.address, homeValueRequest)
            : this.agentContactApiService.addAddressToDashboard(this.propertyId, this.address, homeValueRequest);
        })
      )
      .subscribe({
        next: property => {
          if (property) {
            this.spinnerService.hide(this.spinnerName);
            this.router.navigate(["/dashboard", property.propertyId]);
            this.dialogRef.close(true);
          }
        },
        error: err => {
          this.spinnerService.hide(this.spinnerName);
          if (err instanceof HttpErrorResponse) {
            if (err.status === HttpStatusCode.BadRequest) {
              this.snackBar.open(ERROR_CODE_MESSAGE_MAP[err.error.code], undefined, {duration: 5000});
            } else {
              this.snackBar.open(`Something went Wrong. Please contact Support.`, undefined, {duration: 5000});
            }
          } else {
            this.snackBar.open(`Something went Wrong. Please try again.`, undefined, {duration: 5000});
          }
        }
      });
  }

  scrollToError() {
    setTimeout(() => {
      const el = document.getElementsByClassName("error-field")[0];
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "end"});
      }
    }, 100);
  }
}
