import {AfterViewInit, ChangeDetectorRef, Component, OnInit} from "@angular/core";
import {MatDialogRef} from "@angular/material/dialog";
import {ConsumerConsentService, DestroyableToastSpinnerBase, FelloWebsites, StringUtils} from "../../../../../lib";
import {BasicHomeFactsUpdateRequest, CMAFromDashboardRequest, SaleTimeline} from "fello-model";
import {MatSnackBar} from "@angular/material/snack-bar";
import {NgxSpinnerService} from "ngx-spinner";
import {switchMap, take, tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../../fello-ui-utils";
import {AbstractDashboardService, DashboardSettingsService} from "../../../services";

@Component({
  selector: "lib-improve-home-estimate",
  templateUrl: "./improve-home-estimate.component.html",
  styleUrls: ["./improve-home-estimate.component.scss"]
})
export class ImproveHomeEstimateComponent extends DestroyableToastSpinnerBase implements OnInit, AfterViewInit {
  currentStep = 1;
  data: CMAFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: "",
    basicHomeFacts: {} as BasicHomeFactsUpdateRequest,
    saleTimeline: undefined as unknown as SaleTimeline
  };
  SaleTimeline = SaleTimeline;
  phoneNumberPattern = phoneNumberPattern;
  showErrors = false;
  consentText$ = this.dashboardSettingsService.consentText$;
  felloWebsites = FelloWebsites;
  constructor(
    private dialogRef: MatDialogRef<boolean>,
    private dashboardService: AbstractDashboardService,
    private dashboardSettingsService: DashboardSettingsService,
    private cdr: ChangeDetectorRef,
    snackBar: MatSnackBar,
    spinner: NgxSpinnerService,
    private consentService: ConsumerConsentService
  ) {
    super(spinner, snackBar);
  }

  ngOnInit(): void {
    this.dashboardService.property$
      .pipe(
        take(1),
        tap(property => {
          const vars = property.vars;
          this.data.basicHomeFacts.baths = vars.baths!;
          this.data.basicHomeFacts.beds = vars.beds!;
          this.data.basicHomeFacts.sqft = vars.sqft!;
          this.data.basicHomeFacts.partialBaths = vars.partialBaths!;
          this.data.basicHomeFacts.storiesCount = vars.storiesCount!;
          this.data.basicHomeFacts.parkingCount = vars.parkingCount!;
          this.data.basicHomeFacts.yearBuilt = vars.yearBuilt!;
        })
      )
      .subscribe();
    this.dashboardService.contact$
      .pipe(
        take(1),
        tap(contact => {
          this.data.firstName = contact.fullName ? StringUtils.splitFullName(contact.fullName).first : "";
          this.data.lastName = contact.fullName ? StringUtils.splitFullName(contact.fullName).last : "";
          this.data.phone = contact.phone ?? "";
        })
      )
      .subscribe();
  }

  requestCMA(): void {
    this.withSpinnerAndToast(
      this.consentText$.pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          this.data.consentTrackerId = consentTrackerId;
          return this.dashboardService.submitRequestCMAForm(this.data);
        })
      ),
      {
        successMsg: "CMA Request Sent",
        failureMsg: "Something went wrong.Please Try Again.",
        snackBarConfig: {duration: 4000}
      }
    ).subscribe(() => {
      this.dialogRef.close();
    });
  }

  ngAfterViewInit(): void {
    this.cdr.detectChanges();
  }
}
