import {Component, OnInit, ViewChild} from "@angular/core";
import {
  AddressComponent,
  AgentContactProperty,
  DashboardSettingsResponse,
  HomeValueFromDashboardRequest,
  HVFormConfigSettings,
  PropertyAndContact,
  SaleTimeline
} from "fello-model";
import {ActivatedRoute, Router} from "@angular/router";
import {MatSnackBar} from "@angular/material/snack-bar";
import {map, switchMap, take, takeUntil, tap} from "rxjs/operators";
import {phoneNumberPattern} from "../../../fello-ui-utils/constants/phoneNumberPattern";
import {GoogleStreetViewImageService} from "../../../../lib/services/google-image/google-street-view-image.service";
import {AgentContactDashboardApiService} from "../../../../lib/services/contact-dashboard-api/agent-contact-dashboard-api.service";
import {StringUtils} from "../../../../lib/utils/StringUtils";
import {NgForm} from "@angular/forms";
import {makeStateKey, TransferState} from "@angular/platform-browser";
import {DashboardSettingsService} from "../../services";
import {ConsumerConsentService, DestroyableBase, FelloWebsites} from "../../../../lib";
import {SocialSettingsService} from "../../../fello-ui-lib";

enum ClaimAddressSteps {
  Confirm_Claim,
  Address_Entry,
  Address_Confirmation,
  Claim_Contact_Details,
  New_Address_Contact_Details
}

@Component({
  selector: "lib-claim-address",
  templateUrl: "./claim-address.component.html",
  styleUrls: ["../add-address/agent-contact-add-address.component.scss", "./claim-address.component.scss"],
  providers: [DashboardSettingsService]
})
export class ClaimAddressComponent extends DestroyableBase implements OnInit {
  address = "";
  phoneNumberPattern = phoneNumberPattern;
  addrComp: AddressComponent | null;
  property: AgentContactProperty;
  isLoading = false;
  image: string | null;
  existingEmailId?: string;
  felloWebsites = FelloWebsites;

  currentStep = ClaimAddressSteps.Confirm_Claim;
  consentText$ = this.dashboardSettingsService.consentText$;
  ClaimAddressSteps = ClaimAddressSteps;
  @ViewChild("contactDetailForm") contactDetailForm?: NgForm;

  homeValueFromDashboardRequest: HomeValueFromDashboardRequest = {
    firstName: "",
    lastName: "",
    phone: "",
    emailId: "",
    saleTimeline: undefined
  };
  settings?: DashboardSettingsResponse;
  hvFormSettings: HVFormConfigSettings;
  SaleTimeline = SaleTimeline;
  constructor(
    private route: ActivatedRoute,
    private snackBar: MatSnackBar,
    private googleStreetViewImageService: GoogleStreetViewImageService,
    private router: Router,
    private agentContactApiService: AgentContactDashboardApiService,
    public streetView: GoogleStreetViewImageService,
    private transferState: TransferState,
    private dashboardSettingsService: DashboardSettingsService,
    private socialSettingsService: SocialSettingsService,
    private consentService: ConsumerConsentService
  ) {
    super();
  }

  ngOnInit(): void {
    this.route.data.pipe(map(data => data.propertyAndContact as PropertyAndContact)).subscribe(propertyAndContact => {
      this.property = propertyAndContact.property;
      this.existingEmailId = propertyAndContact.contact.emailId;
      const {first, last} = StringUtils.splitFullName(propertyAndContact.contact.fullName);
      this.homeValueFromDashboardRequest = {
        phone: propertyAndContact.contact.phone,
        lastName: last,
        firstName: first,
        emailId: propertyAndContact.contact.emailId ?? "",
        saleTimeline: propertyAndContact.property.vars.saleTimeline
      };
    });

    this.route.data.subscribe(data => {
      this.hvFormSettings = data.hvFormSettings as HVFormConfigSettings;
      this.settings = data.settings as DashboardSettingsResponse;
    });
    this.dashboardSettingsService.settings$.pipe(takeUntil(this.isDestroyed)).subscribe(settings => {
      this.socialSettingsService.applySocialSettings({
        title: `${this.property.addressComponents.fullAddress} | ${settings?.name ?? ""}`,
        description: "Personalized dashboard with tailored content for real time insights",
        image: "https://hifello.com/hubfs/connect/no_property_image.jpg"
      });
    });
  }

  validateAddress(): void {
    this.addrComp = null;
    this.isLoading = true;
    this.image = null;
    this.agentContactApiService
      .validateAddressDirect(this.address)
      .pipe(
        take(1),
        tap(a => {
          this.googleStreetViewImageService
            .getImage(a)
            .pipe(
              take(1),
              tap(image => {
                if (image) {
                  this.image = image;
                }
              })
            )
            .subscribe();
        })
      )
      .subscribe(
        addr => {
          this.addrComp = addr;
          this.isLoading = false;
          this.currentStep = ClaimAddressSteps.Address_Confirmation;
        },
        () => {
          this.snackBar.open("The address you selected is invalid. Please try with correct address", undefined, {
            duration: 2000
          });
          this.isLoading = false;
        }
      );
  }

  addAddress(): void {
    this.currentStep = ClaimAddressSteps.New_Address_Contact_Details;
  }

  unClaimAndAddAddress(): void {
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }
    this.isLoading = true;
    if (this.existingEmailId) {
      this.consentText$
        .pipe(
          take(1),
          switchMap(consentText => {
            return this.consentService.createConsentTracker(consentText);
          }),
          switchMap(({consentTrackerId}) => {
            homeValueRequest.consentTrackerId = consentTrackerId;
            return this.agentContactApiService.unClaimAddressAndAddAddressToDashboard(
              this.property.propertyId,
              this.address,
              homeValueRequest
            );
          })
        )
        .subscribe(property => {
          this.router.navigate(["/dashboard", property.propertyId]);
        });
    } else {
      this.addEmailAndAddress(this.address);
    }
  }

  claimAddress() {
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }
    this.isLoading = true;
    if (this.existingEmailId) {
      this.consentText$
        .pipe(
          take(1),
          switchMap(consentText => {
            return this.consentService.createConsentTracker(consentText);
          }),
          switchMap(({consentTrackerId}) => {
            homeValueRequest.consentTrackerId = consentTrackerId;
            return this.agentContactApiService.claimAddressFromDashboard(this.property.propertyId, homeValueRequest);
          })
        )
        .subscribe(() => {
          this.transferState.remove(makeStateKey(`prop:${this.property.propertyId}`));
          this.router.navigate(["/dashboard", this.property.propertyId], {
            replaceUrl: true
          });
        });
    } else {
      this.addEmailAndAddress(this.property.addressComponents.fullAddress);
    }
  }

  addEmailAndAddress(rawAddress: string): void {
    const homeValueRequest = {...this.homeValueFromDashboardRequest};
    if (!homeValueRequest.phone) {
      delete homeValueRequest.phone;
    }
    this.consentText$
      .pipe(
        take(1),
        switchMap(consentText => {
          return this.consentService.createConsentTracker(consentText);
        }),
        switchMap(({consentTrackerId}) => {
          homeValueRequest.consentTrackerId = consentTrackerId;
          return this.agentContactApiService.addEmailAndAddressFromDashboard(this.property.propertyId, rawAddress, homeValueRequest);
        })
      )
      .subscribe(property => {
        this.transferState.remove(makeStateKey(`prop:${property.propertyId}`));
        this.router.navigate(["/dashboard", property.propertyId], {
          replaceUrl: true
        });
      });
  }

  scrollToError() {
    setTimeout(() => {
      const el = document.getElementsByClassName("error-field")[0];
      if (el) {
        el.scrollIntoView({behavior: "smooth", block: "center", inline: "end"});
      }
    }, 100);
  }
}
